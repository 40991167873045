<template>
  <div>
    <section class="main min-vh-100 d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <!-- Left Column: Login Form -->
          <div class="col-12 col-md-6">
            <div class="login-box">
              <h2 class="f-secondary text-primary-2 mb-5">Login</h2>
              <ValidationObserver ref="loginForm" v-slot="{ invalid }">
                <form @submit.prevent="submitForm">
                  <!-- Email Input -->
                  <div class="mb-3">
                    <label for="email" class="form-label">Email Address*</label>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                      name="email"
                    >
                      <input
                        type="email"
                        v-model="user.email"
                        id="email"
                        autocomplete="off"
                      />
                      <span class="text-danger small">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- Password Input -->
                  <div class="mb-3">
                    <label for="password" class="form-label">Password*</label>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      name="password"
                    >
                      <input
                        type="password"
                        v-model="user.password"
                        id="password"
                        autocomplete="new-password"
                      />
                      <span class="text-danger small">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- Submit Button -->
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isLoading"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-else>Login</span>
                  </button>
                </form>
              </ValidationObserver>
            </div>
          </div>

          <!-- Right Column: Image -->
          <div class="col-md-6 d-none d-md-block m-0 p-0">
            <img
              src="../../../public/img/stm/landing-img.png"
              class="img-fluid"
              alt="Career Pathways"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import { LOGIN_URL, redirectToTFWRegistrationUrl } from "../../helpers/helper";

// Extend validation rules
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email address" });

export default {
  data() {
    return {
      redirectToTFWRegistrationUrl,
      user: {
        email: "",
        password: "",
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["isSuperCustomer"]),
  },
  methods: {
    ...mapActions(["login", "isEmailBelongsToAcronym"]),
    async submitForm() {
      try {
        const isValid = await this.$refs.loginForm.validate();
        if (!isValid) {
          return;
        }

        this.isLoading = true;

        const data = {
          username: this.user?.email,
          password: this.user?.password,
        };

        const res = await this.isEmailBelongsToAcronym(this.user?.email);

        if (res?.status == 200) {
          const response = await this.login(data);
          if (response.response.status == 401) {
            this.$router.push(
              redirectToTFWRegistrationUrl(this.user?.email || "")
            );
          }
        } else if (res.response.status == 400 && this.isSuperCustomer) {
          this.$router.push(
            redirectToTFWRegistrationUrl(this.user?.email || "")
          );
        } else {
          this.$router.push(LOGIN_URL());
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        this.isLoading = false;
        this.user = {
          email: "",
          password: "",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
